import "./App.css";
import "./style.css";
import Escafinho from "./escafinho.png";
import logo from "./logo-escafa-branco.png";
function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="logo-wrapper">
          <img src={logo} alt="logo-agencia-escafa" className="logo" />
        </div>
        <div className="main-image">
          <i className="fas fa-cogs"></i>
          <img src={Escafinho} alt="escafinho" />
        </div>
        <div className="message">
          <p>
            Nosso site está em manutenção, pois
            <br />
            estamos construindo um novo espacinho para você.
          </p>
          <p>Mas, calma! você não mergulhou à toa.</p>
          <p>
            Clique nos ícones abaixo,
            <br />
            visite nossos outros canais e conheça a Escafa!
          </p>
        </div>
        <footer>
          <div className="icons-container">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/agenciaescafa?utm_medium=copy_link"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://m.youtube.com/channel/UCwPFls64FzIR1BLupRO1XWg"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/message/WWPVHTOZCXHMH1"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>

          <div className="info">
            Agência Escafa LTDA | CNPJ 41.394.534/0001-27
          </div>
          <div className="info-2">contato@agenciaescafa.com</div>
        </footer>
      </div>
    </div>
  );
}

export default App;
